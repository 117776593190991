import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import StuffList from './stuff_list';
import {getInboxAllStuffs, getInboxMyStuffs, getArchiveAllStuffs, getArchiveMyStuffs, fetchStats } from '../../actions/actions';
import {Button, Table} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

class Stuffs extends Component {
  constructor () {
    super();
    this.state = {
      selectedIndex: 1
    };    
  }
  componentWillMount() {
    let path = this.props.location.pathname;
    switch (path)
    {      
      case '/app/channel1/other':
        this.props.getInboxAllStuffs(1);
        this.props.fetchStats(1);
        this.setState(state => ({selectedIndex: 1}));
        window.stuffdone.account.channel_id = "C288JM5C3";
        document.title = 'Inbox';
        break;
      case '/app/channel2': 
        this.props.getInboxMyStuffs(2);
        this.props.fetchStats(2);
        this.setState(state => ({selectedIndex: 2}));
        window.stuffdone.account.channel_id = "CSVE8TEP8";
        document.title = 'Inbox';
        break;
      case '/app/channel2/other':
        this.props.getInboxAllStuffs(2);
        this.props.fetchStats(2);
        this.setState(state => ({selectedIndex: 2}));
        window.stuffdone.account.channel_id = "CSVE8TEP8";
        document.title = 'Inbox';
        break;
      case '/app/archive':
        this.props.getArchiveMyStuffs();
        document.title = 'Archive';
        break;
      case '/app/archive/other':
        this.props.getArchiveAllStuffs();
        document.title = 'Archive';
        break;
      default: 
        this.props.getInboxMyStuffs(1);        
        document.title = 'Inbox';
        window.stuffdone.account.channel_id = "C288JM5C3";
        this.setState(state => ({selectedIndex: 1}));
        break;
    }
  }
    
  render() {    
    let btns;    
    let channel_btns;
    let active_tab = this.props.app.sub_menu;
    let channel = this.state.selectedIndex;
    let myInboxCount = '';
    let allInboxCount = '';        

    if (this.props.app.stats.inbox_my) {
      myInboxCount = `(${this.props.app.stats.inbox_my})`;
    }

    if (this.props.app.stats.opened) {
      allInboxCount = `(${this.props.app.stats.opened +
        this.props.app.stats.inprogress -
        this.props.app.stats.inbox_my})`;
    }

    if (this.props.app.menu === 'inbox') {
      document.title = `Inbox ${myInboxCount}`;      

      channel_btns = (   
        <Button.Group selectedIndex={channel}> 
          <button className = {["ui button app_button", channel === 1 ? 'active' : ''].join(' ')} onClick={() => this.setState(state => ({selectedIndex: 1}))}><span className="btn_span">Bugs</span></button>
          <button className = {["ui button app_button", channel === 2 ? 'active' : ''].join(' ')} onClick={() => this.setState(state => ({selectedIndex: 2}))}><span className="btn_span">Tech</span></button>       
        </Button.Group>     
      );
      btns = (        
        <Button.Group basic>                
          <Link to={"/app/channel" + channel} className={['ui', 'button', active_tab === 'my' ? 'active' : ''].join(' ')}>
            My {myInboxCount}
          </Link>
          <Link to={"/app/channel" + channel+"/other"} className={['ui', 'button', active_tab === 'all' ? 'active' : ''].join(' ')}>
            Other {allInboxCount}
          </Link>
        </Button.Group>
      );
    } else {
      document.title = 'Archive';

      btns = (
        <Button.Group basic>
          <Link to="/app/archive" className={['ui', 'button', active_tab === 'my' ? 'active' : ''].join(' ')}>
            My
          </Link>
          <Link to="/app/archive/other" className={['ui', 'button', active_tab === 'all' ? 'active' : ''].join(' ')}>
            Other
          </Link>
        </Button.Group>
      );
    }

    let stuffs_list;

    // let bugs_list = (
    //   <Segment>
    //     Nice job! <span role="img" aria-label="nice job">👍</span>
    //   </Segment>
    // );

    if (this.props.app.stuffs.length > 0) {
      stuffs_list = (
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="th_header" />
              <Table.HeaderCell>Msg</Table.HeaderCell>
              <Table.HeaderCell>Assignee</Table.HeaderCell>
              <Table.HeaderCell>Tags</Table.HeaderCell>
              <Table.HeaderCell>createdAt</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <StuffList />
        </Table>
      );
    }

    return (
      <Fragment>                          
        <div className="btn_toolbar">
        {channel_btns}
        {" "}
        {btns}
        </div>
        <div className="full">{stuffs_list}</div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    getInboxAllStuffs: (id) => {
      dispatch(getInboxAllStuffs(id));
    },
    getInboxMyStuffs: (id) => {
      dispatch(getInboxMyStuffs(id));
    },
    getArchiveAllStuffs: () => {
      dispatch(getArchiveAllStuffs());
    },
    getArchiveMyStuffs: () => {
      dispatch(getArchiveMyStuffs());
    },
    fetchStats: (id) => {
      dispatch(fetchStats(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Stuffs);
