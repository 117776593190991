import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {getStats, fetchStatsMonths} from '../actions/actions';
import {Button, Table} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

class Stats extends Component {
  constructor () {
    super();
    this.state = {
      selectedIndex: 1
    };  
  }

  componentDidMount() {
    /*this.props.getStats();
    this.props.fetchStatsMonths();*/
    document.title = 'Stats';
  }

  componentWillMount() {
    let path = this.props.location.pathname;
    switch (path)
    {            
      case '/app/stats2': 
        this.props.getStats(2);
        this.props.fetchStatsMonths(2);
        this.setState(state => ({selectedIndex: 2}));        
        break;      
      default: 
        this.props.getStats(1);       
        this.props.fetchStatsMonths(1); 
        this.setState(state => ({selectedIndex: 1}));
        break;
    }
  }

  render() {
    let total = '';
    let months = '';
    let channel_btns;
    let channel = this.state.selectedIndex;

    channel_btns = (   
      <Button.Group selectedIndex={channel}>         
          <Link to={"/app/stats1"} className={['ui button app_button', channel === 1 ? 'active' : ''].join(' ')}>
            Bugs
          </Link>
          <Link to={"/app/stats2"} className={['ui button app_button', channel === 2 ? 'active' : ''].join(' ')}>
            Tech
          </Link>
      </Button.Group>     
    );
    if (this.props.app.stats) {
      total = (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>Total</Table.HeaderCell>
            <Table.HeaderCell>{this.props.app.stats.opened}</Table.HeaderCell>
            <Table.HeaderCell>{this.props.app.stats.inprogress}</Table.HeaderCell>
            <Table.HeaderCell>{this.props.app.stats.fixed}</Table.HeaderCell>
            <Table.HeaderCell>{this.props.app.stats.closed}</Table.HeaderCell>
            <Table.HeaderCell>{this.props.app.stats.submitted}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      );
    }

    if (this.props.app.stats_months.length > 0) {
      months = this.props.app.stats_months.map(month => {
        return (
          <Table.Row key={month.id}>
            <Table.Cell>{month.title}</Table.Cell>
            <Table.Cell>{month.open}</Table.Cell>
            <Table.Cell>{month.inprogress}</Table.Cell>
            <Table.Cell>{month.fixed}</Table.Cell>
            <Table.Cell>{month.closed}</Table.Cell>
            <Table.Cell>{month.submitted}</Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Fragment>        
        <div className="btn_toolbar">        
        {channel_btns}
        </div>        
        <div className="full">
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2} />
                <Table.HeaderCell width={2}>Open</Table.HeaderCell>
                <Table.HeaderCell width={2}>InProgress</Table.HeaderCell>
                <Table.HeaderCell width={2}>Fixed</Table.HeaderCell>
                <Table.HeaderCell width={2}>Closed</Table.HeaderCell>
                <Table.HeaderCell width={2}>Submitted</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{months}</Table.Body>
            {total}
          </Table>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    getStats: (id) => {
      dispatch(getStats(id));
    },
    fetchStatsMonths: (id) => {
      dispatch(fetchStatsMonths(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Stats);
