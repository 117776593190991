import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {getTrelloUsers, getSlackUsers, updateUserTrId} from '../actions/actions';
import {Item, Image, List, Table, Message} from 'semantic-ui-react';


class UsersTab extends Component {
  constructor () {
    super();
    this.state = {
      selectedIndex: 1,
      slackUserName: '',
      trelloUserName: '',
      slackUserSelected: '',
      trelloUserSelected: '',
      message: ''
    }; 
  }

  componentDidMount() {
    document.title = 'Users mapping';
  }

  componentWillMount() {
    let path = this.props.location.pathname;
    this.props.getTrelloUsers();
    this.props.getSlackUsers();
  }

  updateClick() {    
    if (this.state.trelloUserSelected && this.state.slackUserSelected)
    {
      this.props.updateUserTrId(this.state.slackUserSelected, this.state.trelloUserSelected);
      this.setState({ message: this.state.slackUserName + ' has been mapped.'});
    }
    console.log(this.state);
    console.log('map button clicked! ');
  }

  handleClickTrello = (id, name) => {
    console.log(name);
    this.setState({ trelloUserSelected: id , trelloUserName: name});
  };

  handleClickSlack = (id, name) => {
    console.log(name);
    this.setState({ slackUserSelected : id, slackUserName : name });
    let obj = (
      <Item>
        <Item.Image size='tiny' src='https://secure.gravatar.com/avatar/b4704344963de7337d9236464e59d1e4.jpg?s=72&d=https%3A%2F%2Fa.slack-edge.com%2Fdf10d%2Fimg%2Favatars%2Fava_0023-72.png' />
        <Item.Content verticalAlign='middle'>
          <Item.Header as='a'>
            {id}
          </Item.Header>
          <Item.Description>
            Description
          </Item.Description>
        </Item.Content>
      </Item>
    );
    this.setState({ slackUserItem : obj});
  }
  render() {
    
    let channel = this.state.selectedIndex;

    let slackUsers = [];
    let trelloUsers = [];
    if (this.props.app.users.length > 0 && (this.props.app.trelloUsers.length > 0)) 
    {
      slackUsers = this.props.app.users.map(user => {
        return (
        <List.Item key = {user.id} onClick={() => this.handleClickSlack(user.id, user.realname)}>
          <Image avatar src={user.avatar} />
          <List.Content>
          <List.Header>{user.realname}</List.Header>
        </List.Content>
      </List.Item>
     )});

     trelloUsers = this.props.app.trelloUsers.map(user => {
      return (
      <List.Item key = {user.id} onClick = {() => this.handleClickTrello(user.id, user.fullName)}>
        <List.Content>
        <List.Header>{user.fullName}</List.Header>
        <List.Description>{user.id}</List.Description>
      </List.Content>
      </List.Item>
    )});
    };

    let slackUserItem = (
            <Item>
              <Item.Content verticalAlign='middle'>
                <Item.Header as='a'>
                  {this.state.slackUserName}
                </Item.Header>
                <Item.Description>
                  {this.state.slackUserSelected}
                </Item.Description>
              </Item.Content>
            </Item>
    );
    let trelloUserItem = (
      <Item>
      <Item.Content verticalAlign='middle'>
        <Item.Header as='a'>
          {this.state.trelloUserName}
        </Item.Header>
        <Item.Description>
          {this.state.trelloUserSelected}
        </Item.Description>
      </Item.Content>
      </Item>
    );

    let messageBlock = (
      <Message>
      <Message.Header></Message.Header>
      <p>
        {this.state.message}
      </p>
      </Message>
    );
    return (
      <Fragment>        
        <div className="btn_toolbar"> 
        {messageBlock}       
        </div>        
        <div className="full">
        <Table>
        <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={5}> Slack users</Table.HeaderCell>
                <Table.HeaderCell width={2}></Table.HeaderCell>
                <Table.HeaderCell width={5}> Trello users</Table.HeaderCell>
              </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {slackUserItem}
            </Table.Cell>
            <Table.Cell>
                <button className = "ui basic button app_button" onClick={() => this.updateClick()}>  
                  <span className="btn_span">Map</span>
                </button> 
            </Table.Cell>
            <Table.Cell>
             {trelloUserItem}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell verticalAlign = 'top'>
              <List animated selection verticalAlign='middle'>
                {slackUsers}
              </List>
            </Table.Cell>
            <Table.Cell>
           
            </Table.Cell>
            <Table.Cell verticalAlign = 'top'>
            <List animated selection verticalAlign='middle'>
              {trelloUsers}
            </List>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
        </Table>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    getSlackUsers: () => {
      dispatch(getSlackUsers());
    },
    getTrelloUsers: () => {
      dispatch(getTrelloUsers());
    },
    updateUserTrId: (id, trelloId) => {
      dispatch(updateUserTrId(id, trelloId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersTab);
