import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Settings from './settings';
import {fetchStats} from '../actions/actions';

class Nav extends Component {
  componentDidMount() {
    this.props.fetchStats(1);
  }

  render() {
    let menu = [
      {
        id: 1,
        type: 'inbox',
        path: '/app',
        icon: 'inbox',
        active: true,
        title: 'Inbox',
        count: this.props.app.stats.inbox_my,
      },
      {
        id: 2,
        type: 'archive',
        path: '/app/archive',
        icon: 'archive',
        active: false,
        title: 'Archive',
      },
      {
        id: 3,
        type: 'stats',
        path: '/app/stats1',
        icon: 'insert_chart',
        active: false,
        title: 'Stats',
      },
      {
        id: 4,
        type: 'users',
        path: '/app/userstab',
        icon: 'group',
        active: false,
        title: 'Users'
      },
      {
        id: 5,
        type: 'settings',
        path: '/app/settings',
        icon: 'settings',
        active: false,
        title: 'Settings'
      }
    ];

    let links = menu.map(link => {
      let classNames = 'menu_1';

      if (this.props.app.menu === link.type) {
        classNames += ' active';
      }

      let title = link.title;
      if (link.count) {
        title += ` (${link.count})`;
      }

      return (
        <Link key={link.id} to={link.path} className={classNames}>
          <i className="material-icons">{link.icon}</i>
          <span className="title">{title}</span>
        </Link>
      );
    });

    return (
      <div className="nav_wrapper">
        <Settings />
        <Fragment>{links}</Fragment>
        {/* <div className="channel_sprt">
          &nbsp;
        </div>
        <a className="menu_1" href="#">
          <i className="material-icons">notes</i>
          <span className="title">All Channels</span>
        </a>
        <a className="menu_1" href="#">
          <i className="material-icons">folder</i>
          <span className="title">product-tasks</span>
        </a>
        <a className="menu_1" href="#">
          <i className="material-icons">folder</i>
          <span className="title">problematic-partners</span>
        </a> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    fetchStats: (id) => {
      dispatch(fetchStats(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Nav);
