const getName = id => {
  let user = window.stuffdone.users[id];
  return user ? user.name : '';
};

const msgBeautifier = msg => {
  msg = msg.replaceAll('<', '').replaceAll('>', '').replaceAll('&amp;', '&');
  let users = msg.match(/(@U[0-9A-Z]{8})/g);

  if (users) {
    users.forEach(user => {
      user = user.substr(1);
      msg = msg.replace(user, getName(user));
    });
  }

  return msg;
};

module.exports = {
  getName,
  msgBeautifier,
};
