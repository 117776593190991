
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {getStuff} from '../../actions/actions';
import {updateTag} from '../../actions/actions';
import {closeCard} from '../../actions/actions';
import {Grid, Segment, Comment, Label} from 'semantic-ui-react';
import StuffComment from './stuff_comment';

class Stuff extends Component {
    
  constructor(props) {
    super(props);    
    this.state = {isToggleOn: true};
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.postClick = this.postClick.bind(this);
  }

  postClick() {    
    this.props.closeCard(window.stuffdone.account.channel_id, this.props.match.params.id);
  }

  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
    if (this.props.app.stuff[0].tags === "none")
    {     
      this.props.updateTag(this.props.match.params.id, "problematic");    
      this.props.app.stuff[0].tags = "problematic";
    }
    else
    {      
      this.props.updateTag(this.props.match.params.id, "none");    
      this.props.app.stuff[0].tags = "none";
    }
  }  
  
  componentWillMount() {
    this.props.getStuff(this.props.match.params.id);    
  }

  componentDidMount() {            
    document.title = 'Stuff: ' + this.props.match.params.id;          
  }
  
  render() {
    let thread;
    let status;
    let statusStyle = {
      color: '#fff',
    };
    let btn_class = "";

    if (this.props.app.stuff) {
      status = this.props.app.stuff[0].status;      
      if (status === 'opened') {
        statusStyle.backgroundColor = '#ee0701';
      } else if (status === 'fixed') {
        statusStyle.backgroundColor = '#0e8a16';
      } else if (status === 'closed') {
        statusStyle.backgroundColor = '#006b75';
      } else if (status === 'inprogress') {
        statusStyle.backgroundColor = '#fbca04';
      }

      let stuffs = this.props.app.stuff.map(stuff => {
        return <StuffComment key={stuff.id} comment={stuff} />;
      });
      btn_class = this.props.app.stuff[0].tags === 'problematic' ? "ui button app_button orange" : "ui button app_button";                  
      thread = (
        <Segment>
          <Comment.Group>{stuffs}</Comment.Group>
        </Segment>
      );
    }

    const link_web =
      `https://discovercarhiresales.slack.com/conversation/bugs/p${this.props.match.params.id.replace('.', '')}`;
    const link_app = `slack://channel?team=${window.stuffdone.account.team_id}&id=${window.stuffdone.account.channel_id}&message=${this.props.match.params.id}`;    
    return (
      <Fragment>
        <div className="btn_toolbar">
          <Label size="big" style={statusStyle}>
            {status}
          </Label>
          <span className="bug_id">{this.props.match.params.id}</span>
          <button className = "ui basic button app_button" onClick={this.postClick}>  
              <span className="btn_span">Close</span>
          </button>           
          <a target="_blank" href={link_web} className="ui basic button web_button" rel="noopener noreferrer">
            <span className="btn_span">Slack Web</span>
            <span className="new_window" />
          </a>
          <a href={link_app} className="ui basic button app_button">
            <span className="btn_span">Slack App</span>
            <span className="new_window" />
          </a>          
                                    
        </div>
        <div className="full">
          <Grid doubling={true} stackable={true}>
            <Grid.Row>
              <Grid.Column width={9} className="bug_content">
                {thread}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Fragment>
    );
  }
}


const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    getStuff: id => {
      dispatch(getStuff(id));
    },
    updateTag: (id, tags) => {
      dispatch(updateTag(id, tags));
    },
    closeCard: (channel, ts) => {
      dispatch(closeCard(channel, ts));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Stuff);
