import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import '../sass/app.css';

import MainContainer from '../components/main_container';

const Routes = () => (
  <Router>
    <div className="app">
      <MainContainer />
    </div>
  </Router>
);

export default Routes;
