let default_state = {
  stats: {},
  stats_months: [],
  stuffs: [],
  menu: 'inbox',
  sub_menu: 'my',
  users: [],
  trelloUsers: [],
  settings: []
};

function app(state = default_state, action) {
  switch (action.type) {
    case 'GET_STATS':
      return {
        ...state,
        stats: action.state.stats,
        menu: 'stats',
        sub_menu: null,
      };
    case 'FETCH_STATS':
      return {
        ...state,
        stats: action.state.stats,
      };
    case 'FETCH_STATS_MONTHS':
      return {
        ...state,
        stats_months: action.state.stats_months,
      };
    case 'GET_INBOX_ALL_STUFFS':
      return {
        ...state,
        stuffs: action.state.stuffs,
        menu: 'inbox',
        sub_menu: 'all',
      };
    case 'GET_INBOX_MY_STUFFS':
      return {
        ...state,
        stuffs: action.state.stuffs,
        menu: 'inbox',
        sub_menu: 'my',
      };
    case 'GET_ARCHIVE_ALL_STUFFS':
      return {
        ...state,
        stuffs: action.state.stuffs,
        menu: 'archive',
        sub_menu: 'all',
      };
    case 'GET_ARCHIVE_MY_STUFFS':
      return {
        ...state,
        stuffs: action.state.stuffs,
        menu: 'archive',
        sub_menu: 'my',
      };
    case 'CLEAR_STUFF':
      return {
        ...state,
        stuff: null,
      };
    case 'GET_STUFF':
      return {
        ...state,
        stuff: action.state.stuff,
      };
    case 'GET_USERS':
      return {
        ...state,
        users: action.state.users,
      };
    case 'GET_ALLUSERS':
      return {
        ...state,
        users: action.state.users,
      };
    case 'GET_TRELLOUSERS':
        return {
          ...state,
          trelloUsers: action.state.trelloUsers,
        };
    case 'GET_SETTINGS':
        return {
          ...state,
          settings: action.state.settings,
        };
    default:
      return state;
  }
}

export default app;
