import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import Nav from './nav';
import Stats from './stats';
import Stuffs from './stuffs/stuffs';
import Stuff from './stuffs/stuff';
import Dashboard from './dashboard';
import UsersTab from './users';

class MainContainer extends Component {
  render() {
    return (
      <div className="MainContainer">
        <Nav />
        <div className="main">
          <Route exact path="/app/" component={Stuffs} />
          <Route exact path="/app/channel1" component={Stuffs} />
          <Route exact path="/app/channel1/other" component={Stuffs} />
          <Route exact path="/app/channel2" component={Stuffs} />
          <Route exact path="/app/channel2/other" component={Stuffs} />
          <Route exact path="/app/archive" component={Stuffs} />
          <Route exact path="/app/archive/other" component={Stuffs} />
          <Route exact path="/app/stuff/:id" component={Stuff} />
          <Route exact path="/app/stats1" component={Stats} />
          <Route exact path="/app/stats2" component={Stats} />
          <Route exact path="/app/userstab" component = {UsersTab} />
          <Route exact path="/app/settings" component={Dashboard} />
        </div>
      </div>
    );
  }
}

export default MainContainer;
