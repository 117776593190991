/*eslint no-extend-native: "off"*/

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import Routes from './routes/route_root';
import * as log from 'loglevel';

import 'semantic-ui-css/semantic.min.css';

import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

log.setLevel(0);

// some sugar
String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

// set namespace
window.stuffdone = {};
window.stuffdone.users = {};

const store = createStore(rootReducer, applyMiddleware(thunk));

fetch('/api/account', {credentials: 'include'})
  .then(response => {
    return response.json();
  })
  .then(json => {
    json.users.forEach(user => {
      window.stuffdone.users[user.id] = {name: user.realname};
    });

    window.stuffdone.account = json.account;

    ReactDOM.render(
      <Provider store={store}>
        <Routes />
      </Provider>,
      document.getElementById('root'),
    );
  })
  .catch(log.error);
