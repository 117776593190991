import React, {Component} from 'react';
import {Dropdown} from 'semantic-ui-react';

class Settings extends Component {
  render() {
    return (
      <div className="settings">
        <div className="stg">
          <span className="mobile_menu">
            <Dropdown as="i" className="material-icons" text="menu">
              <Dropdown.Menu>
                <a href="/logout" className="item">
                  Logout
                </a>
              </Dropdown.Menu>
            </Dropdown>
          </span>
          <span className="desktop_menu">
            <Dropdown text="StuffDone">
              <Dropdown.Menu>
                <a href="/logout" className="item">
                  Logout
                </a>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
      </div>
    );
  }
}

export default Settings;
