import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {updateTag} from '../../actions/actions';
import {Link} from 'react-router-dom';
import {Table, Checkbox} from 'semantic-ui-react';

import {getName, msgBeautifier} from '../../utils/users';

class StuffList extends Component {
  render() {
    let rows = this.props.app.stuffs.map(stuff => {
      let msg = msgBeautifier(stuff.text);
      let createdAt = moment(stuff.createdAt).fromNow();
      let tags = stuff.tags;
      let statusStyle = {};
      let tagStyle = {};
      if (tags === 'problematic')
      {
        tagStyle.backgroundColor = '#fbca04';
      }      

      if (stuff.status === 'fixed') {
        statusStyle.backgroundColor = '#0e8a16';
      } else if (stuff.status === 'closed') {
        statusStyle.backgroundColor = '#006b75';
      } else if (stuff.status === 'inprogress') {
        statusStyle.backgroundColor = '#fbca04';
      }

      return (
        <Table.Row key={stuff.id}>
          <Table.Cell className="bug_cell_status" style={statusStyle} />
          <Table.Cell>
            <div className="bug_rep_ass">
              <span className="name">{getName(stuff.user_id)}</span>
            </div>
            <div className="stuff_list_link">
              <Link to={'/app/stuff/' + stuff.distinct_id}>{msg}</Link>
            </div>
          </Table.Cell>
          <Table.Cell>{getName(stuff.assigned_id)}</Table.Cell>
          <Table.Cell style = {tagStyle}>
            {tags !== 'none' ? <a href={'https://trello.com/c/' + tags} target="blank">Trello link</a> : ""}             
          
          </Table.Cell>
          <Table.Cell width={2}>{createdAt}</Table.Cell>
        </Table.Row>
      );
    });

    return <Table.Body>{rows}</Table.Body>;
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    updateTag: id => {
      dispatch(updateTag(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StuffList);
