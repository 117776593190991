import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Comment, Image} from 'semantic-ui-react';
import moment from 'moment';
import {msgBeautifier} from '../../utils/users';
import Linkify from 'react-linkify';

class StuffComment extends Component {
  render() {
    let createdAt = moment(this.props.comment.createdAt).fromNow();
    let msg = msgBeautifier(this.props.comment.text) || 'comment is missing';

    let files;
    if (this.props.comment.meta) {
      if (this.props.comment.meta.files) {
        files = this.props.comment.meta.files.map(file => {
          let img = <Image src={file} size="medium" />;

          return (
            <a href={file} target="_blank" rel="noopener noreferrer">
              {img}
            </a>
          );
        });

        files = <Image.Group size="medium">{files}</Image.Group>;
      }      
    }

    return (
      <Comment>
        <Comment.Avatar src={this.props.comment.user.avatar} />
        <Comment.Content>
          <Comment.Author as="span">{this.props.comment.user.realname}</Comment.Author>
          <Comment.Metadata>{createdAt}</Comment.Metadata>
          <Comment.Text>
            <Linkify properties={{target: '_blank'}}>{msg}</Linkify>
            {files}
          </Comment.Text>
        </Comment.Content>
      </Comment>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(StuffComment);
