import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {getSettings, getAllUsers, updateSettings} from '../actions/actions';
import {Dropdown, Form, Message} from 'semantic-ui-react';


class Dashboard extends Component {
  constructor () {
    super();
    this.state = {
      selectedIndex: 1,
      selectedValue: '',
      moderators: [],
      success: false,
      error: false,
    }; 
  }

  componentDidMount() {
    document.title = 'Settings';
  }

  componentWillMount() {
    this.props.getAllUsers();
    this.props.getSettings();
  }

  handleDropChange =(e, {value}) =>  {    
    this.setState({ selectedValue: value.join(',')})
  }

  handleChange = (e, { value }) => {
  
    let arr = value.split(',');
    //only for moderators block
    if (arr.length > 1 && this.props.app.users)
    { 
      this.state.moderators = arr.map(data => {
        return {
          key: data,
          text: this.props.app.users.find(a => a.id === data).realname,
          value: data
        }
      });   
    }
    this.setState({ selectedIndex: e.target.innerText, selectedValue: value, success: false});
  };

  handleSubmit = () => {
    console.log(this.state.selectedIndex);
    console.log(this.state.selectedValue);
    this.setState({success: true});
    this.props.updateSettings(this.state.selectedIndex,this.state.selectedValue);
  }

  render() {
    
    let settingsData = [];
    
    if (this.props.app.settings) {
      settingsData = this.props.app.settings.map(data => {
        return {
          key: data.key,
          text: data.key,
          value: data.value
        }   
      });
    }

    let modetatorsBlock = (
      <Form.Group widths='equal'>
        <Form.Field>
        <Dropdown 
            multiple
            search
            selection
            closeOnChange
            options={this.state.moderators}
            placeholder='Choose'
            onChange = {this.handleDropChange}
          />
        </Form.Field>
        </Form.Group>
    );
    return (
      <Fragment>        
        <div className="btn_toolbar">     
        </div>        
        <div className="full">
        <Form success = {this.state.success} onSubmit={this.handleSubmit}>
        <Message
          success
          header='Form updated'
          content="The settings has been updated."
        />
        <Form.Group widths='equal'>
          <Form.Select
            fluid
            label='Key'
            options={settingsData}
            placeholder='Key'
            onChange={this.handleChange}
          />
          <Form.Input fluid label='Value' onChange={this.handleChange} value={this.state.selectedValue}></Form.Input>
        </Form.Group>
         {modetatorsBlock}
        <Form.Button>Submit</Form.Button>
        </Form>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    getAllUsers: () => {
      dispatch(getAllUsers());
    },
    getSettings: () => {
      dispatch(getSettings());
    },
    updateSettings: (key, value) => {
      dispatch(updateSettings(key, value));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
