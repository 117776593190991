import * as log from 'loglevel';

export function fetchStats(id) {
  return dispatch => {
    fetch('/api/stats/' + id, {
      credentials: 'include',
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        dispatch({
          type: 'FETCH_STATS',
          state: {
            stats: json,
          },
        });
      })
      .catch(log.error);
  };
}

export function fetchStatsMonths(id) {
  return dispatch => {
    fetch('/api/stats/months/' + id, {
      credentials: 'include',
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        dispatch({
          type: 'FETCH_STATS_MONTHS',
          state: {
            stats_months: json,
          },
        });
      })
      .catch(log.error);
  };
}

export function getStats(id) {
  return dispatch => {
    fetch('/api/stats/' + id, {
      credentials: 'include',
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        dispatch({
          type: 'GET_STATS',
          state: {
            stats: json,
          },
        });
      })
      .catch(log.error);
  };
}

export function getInboxAllStuffs(id) {
  return dispatch => {
    fetch('/api/stuffs/inbox/'  + id, {
      credentials: 'include',
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        dispatch({
          type: 'GET_INBOX_ALL_STUFFS',
          state: {
            stuffs: json,
          },
        });
      })
      .catch(error => {
        log.error('getStuffs', error);
      });
  };
}

export function getInboxMyStuffs(id) {
  return dispatch => {
    fetch('/api/stuffs/inbox/my/' + id, {
      credentials: 'include',
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        dispatch({
          type: 'GET_INBOX_MY_STUFFS',
          state: {
            stuffs: json,
          },
        });
      })
      .catch(error => {
        log.error('getStuffs', error);
      });
  };
}

export function getArchiveAllStuffs() {
  return dispatch => {
    fetch('/api/stuffs/archive', {
      credentials: 'include',
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        dispatch({
          type: 'GET_ARCHIVE_ALL_STUFFS',
          state: {
            stuffs: json,
          },
        });
      })
      .catch(error => {
        log.error('getStuffs', error);
      });
  };
}

export function getArchiveMyStuffs() {
  return dispatch => {
    fetch('/api/stuffs/archive/my', {
      credentials: 'include',
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        dispatch({
          type: 'GET_ARCHIVE_MY_STUFFS',
          state: {
            stuffs: json,
          },
        });
      })
      .catch(error => {
        log.error('getStuffs', error);
      });
  };
}

export function getStuff(id) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_STUFF',
    });

    fetch('/api/stuff/' + id, {
      credentials: 'include',
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        dispatch({
          type: 'GET_STUFF',
          state: {
            stuff: json,
          },
        });
      })
      .catch(error => {
        log.error('getStuff', error);
      });
  };
}

export function updateTag(id, tag) {
  return dispatch => {    
    fetch('/api/stuff/updatetag/' + id + "/" + tag, {
      method: 'get',
      credentials: 'include',
    })
      .catch(error => {
        log.error('updatetag', error);
      });
  };
}

export function updateUserTrId(id, trelloId) {
  return dispatch => {
    fetch('/api/users/update/' + id + "/" + trelloId, {
      method: 'get',
      credentials: 'include',
    }).catch(error => {
      log.error('updateUserTrelloId', error);
    });
  }
}

export function closeCard(channel, ts) {
  return dispatch => {
    fetch('/api/stuff/closecard/' + channel + "/" + ts, {
      method: 'get',
      credentials: 'include',
    })
      .catch(error => {
        log.error('closecard', error);
      });
  };
}

export function getSlackUsers() {
  return dispatch => {
    fetch('/api/slackusers', { 
      method: 'get',
      credentials: 'include',
    }).then(response => {
      return response.json();
    })
    .then(json => {
      dispatch({
        type: 'GET_USERS',
        state: {
          users: json,
        },
      });
    })
    .catch(log.error);
  };
};

export function getAllUsers() {
  return dispatch => {
    fetch('/api/users', { 
      method: 'get',
      credentials: 'include',
    }).then(response => {
      return response.json();
    })
    .then(json => {
      dispatch({
        type: 'GET_ALLUSERS',
        state: {
          users: json,
        },
      });
    })
    .catch(log.error);
  };
};


export function getTrelloUsers() {
    return dispatch => {
      fetch('/api/trellousers', { 
        method: 'get',
        credentials: 'include',
      }).then(response => {
        return response.json();
      })
      .then(json => {
        dispatch({
          type: 'GET_TRELLOUSERS',
          state: {
            trelloUsers: json,
          },
        });
      })
      .catch(log.error);
    };
};

export function getSettings() {
  return dispatch => {
    fetch('/api/settings', { 
      method: 'get',
      credentials: 'include',
    }).then(response => {
      return response.json();
    })
    .then(json => {
      dispatch({
        type: 'GET_SETTINGS',
        state: {
          settings: json,
        },
      });
    })
    .catch(log.error);
  };
};


export function updateSettings(key, value) {
  return dispatch => {
    fetch('/api/settings/update/' + key + "/" + value, {
      method: 'get',
      credentials: 'include',
    }).catch(error => {
      log.error('updateSettings', error);
    });
  }
}

